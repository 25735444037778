<!-- 配置员工负责的系统 -->
<template>
    <el-form v-if="viewReady"
        :model="system"
        :rules="rules"
        class="department-config private"
        label-width="150px"
        label-suffix=": "
        ref="sform"
    >
        <div v-show="!isAuthorised" class="notice">
            您没有编辑{{system.systemName}}的权限，不能编辑，仅可查阅</div>
        <el-form-item label="系统" prop="system">
            <el-select v-model="system.system"
                @change="handleSysChange"
                :disabled="isUpdate"
                class="width3">
                <el-option
                    v-for="s in sysOpts"
                    :key="s.sysCode"
                    :label="s.name"
                    :value="s.sysCode"
                    :disabled="!s.owned || !!s.isSet"
                ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="设置岗位" prop="postId">
            <el-select v-model="system.postId"
                @change="handlePostChange"
                class="width3">
                <el-option
                    v-for="p in postList"
                    :key="p.id"
                    :label="p.name"
                    :value="p.id"
                ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="所属部门/小组" prop="locate">
            <el-input
                v-model="system.departmentMixName"
                @focus="handleLocateFocus"
                class="width3"
                placeholder="请选择"
            ></el-input>

            <el-button type="text" class="ml2"
                @click="handleLocateFocus"
            >选择</el-button>

            <PickerTree
                @input="handlePick"
                :value="system.locate"
                :tree="pickerTree"
                :show.sync="showPicker"
                :expand-keys="defaultExpandedKeys"
                :rules="pickerValidRules"
            ></PickerTree>
        </el-form-item>

        <el-form-item label="查看数据权限范围" prop="manage"
            class="wrap">
            <el-input
                value=""
                @focus="handleManageFocus"
                class="width3"
                placeholder="请选择"
            ></el-input>

            <el-button type="text" class="ml2"
                @click="handleManageFocus"
            >选择</el-button>

            <div class="addition-text">
                <span class="addition-text1">(负责部门/小组)</span>
                <span class="addition-text1">岗位为人事, 财务, 运营, 主管或经理以上的才需填写</span>
            </div>
            
            <CheckerTree
                v-model="system.manage"
                :show.sync="showChecker"
                :tree="checkerTree"
                :expand-keys="defaultExpandedKeys"
                :rules="checkerValidRules"
            ></CheckerTree>

            <ul class="depart-list">
                <li v-for="de in system.manage"
                    :key="de.id">
                    <span>{{ de.prenames }}</span>

                    <i class="el-icon-close"
                        @click="handleDeleteDepart(de)"
                    ></i>
                    <!-- <el-button icon="el-icon-delete"
                        type="text"
                    ></el-button> -->
                </li>
            </ul>
        </el-form-item>

        <el-form-item class="buttons">
            <el-button
                type="primary"
                @click="handleSaveButton(true)"
                v-dbclick
            >确认添加系统</el-button>
        </el-form-item>
    </el-form>

</template>

<script>
import Tree from "../../../utils/Tree";
import PickerTree  from "./PickerTree";
import CheckerTree from "./CheckerTree";
export default {
    name: 'DepartmentConfig',
    props: ["system", "allsystem"],
    components: {
        PickerTree,
        CheckerTree
    },

    data () {
        return {
            rules: {
                system: { required: true, message: "请选择系统",    trigger: "blur" },
                postId: { required: true, message: "请选择岗位",    trigger: "blur" },
                locate: { required: true, message: "请选择所属部门", trigger: "none" }
            },
            postList: [],
            treeIns: new Tree(),

            showPicker: false,
            pickerTree: [],
            pickerValidRules: [],

            showChecker: false,
            checkerTree: [],
            checkerValidRules: [],
            defaultExpandedKeys: [],
            allOrganization: [], // 保存原始allOrganization
        }
    },

    computed: {
        viewReady () {
            return !!this.system
        },
        isUpdate () {
            return !!this.system?.id
        },
        sysOpts () {
            return this.systemEnum.map(sys => {
                sys.isSet = (this.allsystem||[]).some(({system}) => 
                    sys.sysCode === system)

                return sys;
            })
        },
        isAuthorised () {
            if (!this.viewReady) return false;
            if (!this.isUpdate) return true;
            return this.userInfo.empInfoList.some(({system}) =>
                system === this.system.system)
        }
    },

    watch: {
        system: async function (data) {
            if (data) {
                if (data.system) {
                    const sys = this.systemEnum.find(
                        it => it.sysCode === data.system
                    )
                    try {
                        const loading = this.$loading();
                        await this.fetchOrgaList(sys.id);
                        await this.fetchPostList(sys.id);
                        loading.close();
                        this.$refs.sform.clearValidate();
                    } catch (reason) {
                        console.warn("监听切换时程序出错", reason);
                        
                    }
                }
            }
        }
    },

    methods: {
        handleSysChange (val) {
            this.resetSystem()
            const sys = this.systemEnum.find(
                it => it.sysCode === val
            )
            this.system.systemName = sys.name;
            this.fetchOrgaList(sys.id);
            this.fetchPostList(sys.id);
        },
        async fetchPostList (sysId) {
            this.postList = [];
            const postId = this.system?.postId;
            try {
				const res = await this.$axios({
					url: "/api/post/queryPostAll",
					method: "post",
					data: { sysId, isNext: 1, postId }
				});

				if (res.code === 2000) {
					this.postList = res.data.postAll;
                    const post = res.data.postAll.find(({id}) => id == postId);
                    this.system.postLevel = post?.level || 0;
                    this.setPickerAndCheckor(postId);

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取岗位失败", reason);
			}
        },
        async fetchOrgaList (sysId) {
            this.treeIns = new Tree()
            try {
				const res = await this.$axios({
					url: "/api/org/queryOrgAll",
					method: "post",
					data: {sysId}
				});

				if (res.code === 2000) {
                    const {empInfoList} = this.userInfo,
                        accoutSystem = empInfoList.find(it=>it.system==this.system.system),
                        accoutLocateId = parseInt(accoutSystem?.locateId),
                        accoutManageId = accoutSystem?.manageId ? accoutSystem.manageId.split(",").map(id =>
                            parseInt(id)) : [];
					const list = res.data.allOrganization.map(it=>{
                        let {preids} = it;
                        preids = preids.split("/").map(Number).filter(Boolean);
                        it.disabled = (accoutManageId.length ? !preids.some(id => accoutManageId.some(_id => _id === id)) : true) || (accoutSystem.postLevel > 10 && it.level <= 3);
                        it.pickable = (!it.disabled || (accoutLocateId ? preids.some(id => id === accoutLocateId) : false)) && (accoutSystem.postLevel <= 10 || it.level > 3);
                        return it;
                    });
                    this.allOrganization = JSON.parse(JSON.stringify(list)); // 保留原始数据

                    this.treeIns = new Tree({
                        list,
                        parentKey: "pid"
                    })

                    this.defaultExpandedKeys = res.data.allOrganization.filter(
                        ({level, code}) =>
                            level == 3 ||                                // 默认展开第三级
                            code == this.system.locate ||                // 选中的所属需要展开
                            this.system.manage.some(m => m.code == code) // 选中的负责也一样
                        ).map(({code})=>code);
                    this.pickerTree = this.checkerTree =
                        JSON.parse(JSON.stringify(this.treeIns.children));

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取组织架构失败", reason);
			}
        },

        handlePostChange (postId) {
            this.setPickerAndCheckor(postId);
            const post = this.postList.find(({id}) => id == postId);
            if (post?.level > 30 && this.system.locateId) {
                const locateObj = this.allOrganization.find(({id}) => id == this.system.locateId);
                if (locateObj?.level < 5) {
                    this.system.locate = this.system.locateId = this.system.departmentMixName = "";
                    this.system.locateLevel = locateObj.level;
                }
            }
            if (post?.level > 40) {
                this.system.manage = this.system.manage.filter(({level}) => {
                    if ((!level) || level > 100) return true;
                    else return false;
                })
            }
        },

        setPickerAndCheckor (postId) {
            console.warn("set picker and checkor");
            try {
                if (!postId) throw "没有PostId";
                const post = this.postList.find(({id}) => id == postId);
                this.system.postLevel = post.level;
                if (post.level > 30) {
                    // const allOrganization = JSON.parse(JSON.stringify(this.allOrganization));
                    // this.pickerTree = new Tree({
                    //     list: allOrganization.map(it=>{
                    //         it.pickable = it.pickable && it.level > 4;
                    //         return it;
                    //     }),
                    //     parentKey: "pid"
                    // }).children;
                    this.pickerValidRules = [{
                        level: [5],
                        message: `${post.name}所属只能选择大区以下的部门或小组`
                    }]
                }

                else {
                    // this.pickerTree = JSON.parse(JSON.stringify(this.treeIns.children));
                    this.pickerValidRules = [];
                }

                if (post.level > 40) {
                    // const allOrganization = JSON.parse(JSON.stringify(this.allOrganization));
                    // this.checkerTree = new Tree({
                    //     list: allOrganization.map(it=>{
                    //         it.disabled = it.disabled || it.level < 6;
                    //         return it;
                    //     }),
                    //     parentKey: "pid"
                    // }).children;

                    this.checkerValidRules = [{
                        level: [100], // 岗位等级大于30的，不能选所有的
                        message: `${post.name}不能设置负责区域/部门/小组`
                    }];
                }
                else {
                    // this.checkerTree = JSON.parse(JSON.stringify(this.treeIns.children));
                    this.checkerValidRules = [];
                }

            } catch (reason) {
                console.log("根据岗位id设置选择器禁用状态抛出", reason);
                this.pickerTree = this.checkerTree = JSON.parse(JSON.stringify(this.treeIns.children))
            }
        },

        resetSystem () {
            this.system.postId = ""
            this.system.locate = ""
            this.system.manage = []
            this.system.systemName = ""
            this.system.departmentMixName = ""
            this.system.postLevel = ""
            this.system.locateLevel = ""
            this.system.saveState = this.system.saveState ? 2 : 0
        },

        handleLocateFocus () {
            this.showPicker = true
        },
        handlePick (data) {
            this.system.locate = data?.code || "";
            this.system.locateId = data?.id || "";
            this.system.locateLevel = data?.level || 0;
            this.system.departmentMixName = data?.prenames || ""
        },
        handleManageFocus () {
            this.showChecker = true
        },

        async handleDeleteDepart (item) {
            try {
                await this.$confirm(
                    "确定删除该负责部门吗?",
                    "提示"
                )

                const list = this.system.manage;
                const index = list.indexOf(item);
                list.splice(index, 1);
                
            } catch (reason) {
                console.log("取消");
            }
        },
        
        async handleSaveButton (manual=false) {
            try {
                await this.$refs.sform.validate();
                this.$emit("save", manual)
            } catch (reason) {
                console.log("填写不正确");
                return false
            }
        }
    }
}
</script>

<style lang='scss' scoped>
    .department-config {
        padding: 32px;
        min-height: 60px;
        background-color: #ECF0F4;
    }

    .depart-list {
        width: 400px;
        line-height: 1;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 12px;
            margin-top: 12px;
            background: #E7E7E7;
            border-radius: 4px;

            i {
                cursor: pointer;
                color: #C3C3C3;

                &:hover {
                    color: inherit;
                }
            }
        }
    }
    
    /deep/ {
        .el-icon-arrow-up:before {
            content: "\e78f";
            color: #646566;
        }
    }

    .notice {
        margin-left: 150px;
        margin-bottom: 16px;
        color: orangered;
    }

    .addition-text {
        position: relative;

        span:first-child{
            position: absolute;
            left: -128px;
            top: -16px;
            color: #606266;

            + span {
                color: orangered;
            }
        }
    }
</style>