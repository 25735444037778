import forList from "@/mixin/forList";
import WriteOff from "./WriteOff.vue";
import LinkAccount from "./LinkAccount.vue";
import ViewParams from "./ViewParams.vue";
import ViewUnionid from "./ViewUnionid.vue";

import {searchTypeEnum, stateEnum, isOrNotEnum} from "./config";

export default {
    name: "StaffList",
    mixins: [ forList ],
	components: {WriteOff, LinkAccount, ViewParams, ViewUnionid},

    data() {
        return {
			helpFile: this.$baseURL + "/upload/docFile/systomeExplain.docx",
			searchBarLabelWidth: 120, // 不会改变，不用追踪
            SP_: {
                search_type  : 1,
				search_value : "",
				search_regin : "",
				search_dept  : "",
				search_post  : "",
				search_state : "",
				search_weixin: ""
            },
			searchTypeEnum,
			reginList: [],
			deptList : [],
			postList : [],
            stateEnum,
			isOrNotEnum,
			writingOff : null,
			detailData : null,
			linkingItem: null,
			unionidItem: null,
            showResetDia: false,
            resetDiaTips: '',
            resetBefore: true,
            isSelf: false,//是不是当前登录人
            clickrow: '',
        };
    },

	computed: {
		sysOpts () {
			return [
				{
					name: "全部",
					id: 0
				},
				...this.systemEnum
			]
		}
	},

    methods: {
        handleResetDia(row) {
            this.clickrow = row

            this.resetBefore = true
            this.resetDiaTips = `是否确认重置 ${row.name} 员工的密码？`
            this.showResetDia = true
            if (this.userInfo.usercode == row.usercode) {
                this.isSelf = true
            } else {
                this.isSelf = false
            }
        },
        handClose() {
            this.showResetDia = false
            if (this.isSelf) {
                this.handleSignOut()
                console.log('退出登录');
            }
        },
        handCloseTop() {
            if (this.showResetDia) {
                this.showResetDia = false
                if (this.isSelf) {
                    this.handleSignOut()
                    console.log('退出登录');
                }
            }
        },
        // 退出
        async handleSignOut() {
            try {
                const res = await this.$axios({
                    url: "/api/outSystem",
                    method: "post"
                })

                if (res.code === 2000) {
                    this.$store.dispatch("unstoreUser");
                } else throw res;

            } catch (reason) {
                if (reason != "cancel") {
                    console.warn("退出失败, reason");
                }
            }
        },

        async resectPsd() {
            const loading = this.$loading({
                target: ".showResetDia .el-dialog",
            });
            const res = await this.$axios({
                url: "/api/employee/resetEmpPassword",
                method: "post",
                data: {
                    // account: 'tyxiaoshou',
                    account: this.clickrow.account,
                },
            });
            loading.close();
            if (res.code === 2000) {
                console.log('接口请求完成');
                // res.pad = 'jinding666'
                this.resetDiaTips = `重置成功并微信通知员工，密码为：${res.data.password}`
                this.resetBefore = false

                // console.log(this.userInfo.usercode);

            } else {
                this.$message({
                    message: res.msg || "请求数据失败",
                    type: "warning",
                });
            }
        },
		callBackForListState (state) {
			if (state.search_sys) {
				this.fetchSubOrg(4)
				this.fetchPostList(state.search_sys)
			}
			if (state.search_regin) this.fetchSubOrg(5, state.search_regin)
		},

		async fetchData() {
			const loading = this.$loading();

			try {
				const data = this.extractParams();

				if (data.search_sys) {
					const sys = this.systemEnum.find(it =>
						it.id === data.search_sys)
					data.search_sys = sys?.sysCode
				}
				const res = await this.$axios({
					url: "/api/employee/getEmpList",
					method: "post",
					data
				})

				if (res.code === 2000) {
					this.total = res.data.count;
					const list = res.data.empList.map(item => {
						item.sysNameList = item.sysName?.split(",").filter(Boolean);
						item.followList = item.unionidSys?.split(",").filter(Boolean);
						return item;
					});
					this.tableData = list;

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
		},

		/**
		 * 获取系统的区域或部门
		 * @param {number} level 4区域 5部门
		 */
		async fetchSubOrg(level=4, pId) {
			try {
				const res = await this.$axios({
					url: "/api/org/queryLevelOrg",
					method: "post",
					data: {
						sysId: this.SP.search_sys,
						level, pId
					}
				})

				if (res.code === 2000) {
					const list = res.data.levelOrgList;
					switch (level) {
						case 4:
							this.reginList = list;
							break;
						case 5:
							this.deptList = list;
							break;
						default:
							break;
					}

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取区域或部门失败", reason);
			}
		},

		// 获取系统的岗位
        async fetchPostList (sysId) {
            this.postList = [];
            try {
				const res = await this.$axios({
					url: "/api/post/queryPostAll",
					method: "post",
					data: { sysId }
				});

				if (res.code === 2000) {
					this.postList = res.data.postAll;

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取岗位失败", reason);
			}
        },

        // 点击导出
        async handleExportBtn () {
			let loadingRef;
			try {
				await this.$confirm("确定导出吗", "系统提示")
				loadingRef = this.$loading();

                const data = this.extractParams(true);
				if (data.search_sys) {
					const sys = this.systemEnum.find(it =>
						it.id === data.search_sys)
					data.search_sys = sys?.sysCode
				}

                const res = await this.$axios({
                    url: "/api/employee/downEmpInfo",
                    method: "post",
					data
                })

				if (res.code === 2000) {
					// this.$message({
                    //     message: "已加入导出任务，去消息管理的下载通知中查看吧",
                    //     type: "success"
                    // })
					window.open(this.$baseURL + "/" + res.data.pathInfo.path)
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
        },

		// 点击 "新增菜单"
		handleAddBtn() {
			this.$router.push({
				path: "/staff/list/add",
				query: {sys_id: this.SP_.search_sys}
			});
		},

		handleReginChange (val) {
			this.SP_.search_dept  = "";
			this.deptList         = [];
			this.fetchSubOrg(5, val);
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(data) {
			this.$router.push({
				path: `/staff/list/edit/${data.id || 5}`,
				query: {sys_id: data.sys_id}
			});
		},

		// 离职
		handleWriteOff (data) {
			this.writingOff = data;
		},

        // 查看详细参数
        handleInParam(index) {
            const item = this.tableData[index];
            this.detailData = item.sysNameList
        },

        // 查看认证信息
        handleUnionId(index) {
            const item = this.tableData[index];
            this.unionidItem = item
        },

		// 启用禁用
		async handleUpdate (data) {
			let {accState, accId} = data;
			let actionText = accState == 1 ? "禁用" : "启用";
			let loadingRef;
			try {
				await this.$confirm(`确定要${actionText}吗`, "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/employee/updateAccountState",
					method: "post",
					data: { accId, state: accState == 1 ? 0 : 1 }
				})

				if (res.code === 2000) {
					this.$message({
						message: `${actionText}成功`,
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();

				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: `${actionText}失败`,
						type: "warn"
					});
				}
				loadingRef && loadingRef.close();
			}
		},

		// 点击表格行操作按钮: 删除
		async handleDelete(data) {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/employee/deleteEmp",
					method: "post",
					data: { id : data.id }
				})

				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		},

		// 点击表格行操作按钮: 日志
		handleLogs(data) {
			this.$router.push({
				path: `/staff/list/logs/${data.id || 5}`,
				query: {sys_id: data.sys_id}
			});
		},

		// 离职
		handleLinkAccount (data) {
			this.linkingItem = data;
		},

        clearSP_trim () {
            this.SP_.search_type = 1;
        },

		handleSysChange (val) {
			this.SP_.search_regin = "";
			this.reginList        = [];
			this.SP_.search_dept  = "";
			this.deptList         = [];
			this.SP_.search_post  = "";
			this.postList         = [];
			if (val) {
				this.fetchSubOrg(4);
				this.fetchPostList(val)
			}
		}
    },

	created () {
		this.auth.save        = this.$hasAuthFor("api/employee/saveEmp");
		this.auth.export      = this.$hasAuthFor("api/employee/downEmpInfo");
		this.auth.update      = this.$hasAuthFor("api/employee/updateAccountState");
		this.auth.writeOff    = this.$hasAuthFor("api/employee/querySysPostEmpInfo");
		this.auth.delete      = this.$hasAuthFor("api/employee/deleteEmp");
		this.auth.logs        = this.$hasAuthFor("api/employee/queryEmpHis");
		this.auth.linkAccount = this.$hasAuthFor("api/employee/saveTeamAccount");

        this.auth.reset = this.$hasAuthFor("api/employee/resetEmpPassword");

	},

    // watch : {
    //     "SP.search_sys" (val) {
	// 		this.SP_.search_regin = "";
	// 		this.reginList        = [];
	// 		this.SP_.search_dept  = "";
	// 		this.deptList         = [];
	// 		if (val) this.fetchSubOrg(4);
    //     }
    // }
};