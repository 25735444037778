<!-- 选择树 -->
<template>
    <el-dialog class="picker-tree"
        :visible="show"
        @close="handleClose"
        :title="title"
        width="500px">

        <el-tree
            :data="tree"
            node-key="code"
            ref="tree"
            :expand-on-click-node="false"
            :current-node-key="value"
            :default-expanded-keys="expandKeys"
            :props="treeProps"
            :render-content="render"
            @node-click="handleNodeClick"
        ></el-tree>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'PickerTree',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        tree: {
            type: Array,
            default () {
                return {
                    name: "全部",
                    text: "全部",
                    children: []
                }
            }
        },
        value: {
            type: String,
            default: ""
        },
        treeProps: {
            type: Object,
            default () {
                return {
                    children: 'children',
                    label: 'name'
                }
            }
        },
        title: {
            type: String,
            default: "选择部门"
        },
        expandKeys: {
            type: Array,
            default () {
                return [58]
            }
        },
        rules: {
            type: Array,
            default () {
                return []
            }
        }
    },

    data () {
        return {
            currentNode: null
        }
    },

    watch: {
        show: async function (val) {
            if (!val) this.$refs.tree.setCurrentKey(null);
            else {
                await this.$nextTick();
                this.$refs.tree.setCurrentKey(this.value);
                this.currentNode = this.$refs.tree.getCurrentNode();
            }
        }
    },

    methods: {
        render (createElement, {data}) {
            return createElement(
                "div",
                {
                    class: data.pickable ? "" : "disabled"
                },
                data.name || ""
            )
        },

        // 点击树的节点
        handleNodeClick(data) {
            const {pickable} = data;
            if (pickable) {
                this.$refs.tree.setCurrentNode(data);
                this.currentNode = data;
            }
            else {
                console.log("不能选择该节点", data?.code);
                if (this.currentNode)
                    this.$refs.tree.setCurrentNode(this.currentNode);
                else
                    this.$refs.tree.setCurrentKey(this.currentNode);
            }
        },

        validate(item) {
            return this.rules.every(({level, message}) => {
                const min = typeof level[0] == "number" ? level[0] : -Infinity;
                const max = typeof level[1] == "number" ? level[1] : Infinity;
                if (item.level < min || item.level > max) {
                    this.$message({
                        message,
                        type: "error"
                    });
                    return false;
                }

                return true;
            })
        },

        handleClose () {
            this.$emit("update:show", false);
        },

        handleSave () {
            const node = this.$refs.tree.getCurrentNode();
            if (!this.validate(node)) return;
            this.$emit("input", node);
            this.handleClose();
        }
    }
}
</script>

<style lang='scss' scoped>
    /deep/ {
        .el-tree-node__content:hover,
        .el-tree-node:focus > .el-tree-node__content {
            background-color: inherit;
        }
        .is-current > .el-tree-node__content {
            background-color: #ecf5ff !important;
        }
        // .is-current {
        //     background-color: #ecf5ff;
        // }
        .disabled {
            color: darkgray
        }
    }

</style>