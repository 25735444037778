<!-- 查看unionid -->
<template>
    <el-dialog
        :visible="viewIsReady"
        @close="handleClose"
        title="认证信息"
        width="600px"
    >

        <ul class="cell-list" v-if="params">
            <li>
                <span class="tag">员工信息</span>
                <span class="value">{{ params.usercode }} - {{ params.name }} - {{ params.phone }}</span>
            </li>
            <li>
                <span class="tag">认证微信的unionid</span>
                <span class="value">{{ params.unionid }}</span>
                <copy-text :text="params.unionid" class="copy-btn"></copy-text>
            </li>
            <li v-for="item in params.unionidList" :key="item.openid">
                <span class="tag">{{ item.appname }}openid</span>
                <span class="value">{{ item.openid }}</span>
                <copy-text :text="item.openid" class="copy-btn"></copy-text>
            </li>
        </ul>

        <div slot="footer">
            <el-button @click="handleClose" type="primary">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'ViewParams',
    props: ["params"],

    data () {
        return {
            viewIsReady: false,
            info: []
        }
    },

    watch: {
        params (params, old) {
            if (params && params !== old) {
                this.viewIsReady = true;
                this.info = params;
            }
        }
    },

    methods: {
        handleClose () {
            this.viewIsReady = false;
            this.info = [];
            this.$emit("update:params", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .cell-list {
        line-height: 1.5;

        li {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .tag {
                color: darkgray;

                &::after {
                    content: ":";
                    margin-right: 6px;
                }
            }

            .copy-btn {
                margin-left: 20px;
            }
        }

        li:nth-child(n+2) {
            margin-top: 10px;
        }
    }
</style>