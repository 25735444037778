/**
 * 这个文件定义了编辑页的混入代码
 */

 export default {

    data () {
        return {

            loading: false, // 获取数据中
            posting: false, // 上传数据中
        };
    },

    computed: {

        // 状态：提交按钮不可用
        submitDisabled () {
            return this?.loading || this?.posting
        }

    },

    methods: {

        // 获取页面数据
        fetchData() {
            console.log("当前页面组件没有实现或不需要 fetchData 方法");
        },
        
        // 点击提交按钮
        handleSubmitButton () {
            console.log("点击提交了, 好像页面没有实现 handleSubmitButton 方法")
        }
    },

    created() {
        const id = Number(this.$route.params.id);
        if (id) {
            if (this.PB) {
                this.PB.id = id;
            } else {
                this.id = id;
            }
            this.fetchData();
        }
    }

}