import forList from "@/mixin/forList";

export default {
    name: "StaffLogs",
    mixins: [ forList ],
    noSaveState: true,
    noFetchFirst: false,
    data() {
        return {
            SP_: {},
            id: '',
            tabitem: 1,
            empInfoMap: "",
        };
    },
    created() {
        console.log(this.$route);
        this.id = this.$route.params.id;
        this.getDetail();
    },

    methods: {
		async fetchData() {
			const loading = this.$loading();

			try {
				const data = {
					...this.extractParams(),
					empId: parseInt(this.$route.params.id) || 5
				};

				const res = await this.$axios({
					url: "/api/employee/queryEmpHis",
					method: "post",
					data
				})

				if (res.code === 2000) {
					this.total = res.data.count;
					const list = res.data.empHisList;
					this.tableData = list;

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
        },
        async getDetail() {
            let loadingRef;
            try {
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/employee/queryEmpDetailInfo",
                    method: "post",
                    data: {
                        empId: this.id,
                    },
                });
                if (res.code === 2000) {
                    this.empInfoMap = res.data.empDetailInfo;
                    // this.channelInfoMap.phoneShow = ''
                    // if (this.channelInfoMap.phone) {
                    //     this.channelInfoMap.phoneShow =
                    //         this.channelInfoMap.phone.replace(
                    //             /(\d{3})\d{4}(\d{4})/,
                    //             "$1****$2"
                    //         );

                    // }
                    loadingRef && loadingRef.close();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.log(reason);
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "获取失败",
                        type: "warning",
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        // 切换tab
        handTab(index) {
            this.tabitem = index;
        },
        // 返回
        handleClose() {
            this.$router.go(-1);
        },
    }
};