<!-- 选择树 -->
<template>
    <el-dialog class="checker-tree"
        :visible="show"
        :title="title"
        @close="handleClose"
        width="500px">

        <el-tree
            :data="tree"
            :props="treeProps"
            :default-expanded-keys="expandKeys"
            :check-strictly="true"
            show-checkbox
            node-key="code"
            ref="tree"
        ></el-tree>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'CheckerTree',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        tree: {
            type: Array,
            default () {
                return {
                    name: "全部",
                    text: "全部",
                    children: []
                }
            }
        },
        value: {
            type: Array,
            default () {
                return []
            }
        },
        treeProps: {
            type: Object,
            default () {
                return {
                    children: 'children',
                    label: 'name'
                }
            }
        },
        title: {
            type: String,
            default: "选择部门"
        },
        expandKeys: {
            type: Array,
            default () {
                return [58]
            }
        },
        rules: {
            type: Array,
            default () {
                return []
            }
        }
    },

    data () {
        return {}
    },

    watch: {
        show: async function (val) {
            if (!val) {
                this.$refs.tree.setCheckedKeys([])
            }
            else {
                await this.$nextTick();
                this.$refs.tree.setCheckedKeys(this.value.map(it => it.code))
            }
        }
    },

    methods: {

        validate(nodes) {
            return nodes.every(item => this.rules.every(({level, message}) => {
                const min = typeof level[0] == "number" ? level[0] : -Infinity;
                const max = typeof level[1] == "number" ? level[1] : Infinity;
                if (item.level < min || item.level > max) {
                    this.$message({
                        message,
                        type: "error"
                    });
                    return false;
                }

                return true;
            }))
        },

        handleSave() {
            const nodes = this.$refs.tree.getCheckedNodes();
            if (!this.validate(nodes)) return;
            this.$emit("input", nodes);
            this.handleClose();
        },

        handleClose () {
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped></style>