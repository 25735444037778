<!-- 添加、编辑字典项 -->
<template>
    <el-dialog class="write-off"
        :visible="viewIsReady"
        @close="handleClose"
        title="员工离职"
        width="560px">

        <div class="name">姓名: {{ name }}</div>
        <ul>
            <li v-for="sys in sysList"
                :key="sys.id">
                <div class="sysname">{{ sys.sysName }}</div>
                <div class="vice">{{ sys.postName }} - {{ sys.prenames }}</div>
                <el-button @click.stop="handleBtn(sys)">去离职</el-button>
            </li>
        </ul>

        <div slot="footer">
            <el-button @click="handleClose" type="primary">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'WriteOff',
    props: ["item"],

    data () {
        return {
            viewIsReady: false,
            sysList: []
        }
    },

    computed: {
        name () {
            return this.item?.name || ""
        },

        // sysList () {
        //     const codes = this.item?.empsystemcode;
        //     if (codes) return codes.split(",").map(
        //         code => this.systemEnum.find(it => it.sysCode === code)
        //     ).filter(Boolean);
        //     else return []
        // }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.fetchList(item.id)
                this.viewIsReady = true;
            }
        }
    },

    methods: {
        async fetchList (empId) {
            const loading = this.$loading({
                target: ".write-off .el-dialog"
            });

            try {
				const res = await this.$axios({
					url: "/api/employee/querySysPostEmpInfo",
					method: "post",
					data: {empId}
				});

				if (res.code === 2000) {
					this.sysList = res.data.empInfo;

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
        },

        async handleBtn(sys) {
            if (sys.system === "CEN") {
                let loadingRef;
                try {
                    await this.$confirm("确定要离职吗", "系统提示")
                    loadingRef = this.$loading();
                    const res = await this.$axios({
                        url: "/api/employee/outEmp",
                        method: "post",
                        data: { empId : this.item.id }
                    })

                    if (res.code === 2000) {
                        this.$message({
                            message: "离职成功",
                            type: "success"
                        });
                        loadingRef && loadingRef.close();
                        this.handleClose();
        
                    } else if (res.code !== 1003) 
                        throw res;

                } catch (reason) {
                    if (reason !== "cancel") {
                        this.$message({
                            message: "离职失败",
                            type: "warn"
                        });
                    }
                    loadingRef && loadingRef.close();
                }
            }
            else {
                try {
                    if (!sys.outUrl) return console.log("改配置没有找到匹配的跳转地址", sys);

                    const loading = this.$loading();
                    const res = await this.openSystemURL({
                        url: sys.outUrl,
                        // url: "http://localhost:8030/#/core/staff",
                        system: sys.system,
                        params: {
                            empCode: this.item.usercode
                        }
                    })
                    loading.close();
                    // if (res !== 2000) throw res;

                } catch (reason) {
                    console.warn("处理离职跳转时出错", reason)
                }
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.sysList = [];
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    li {
        display: flex;
        justify-content: space-between;
        padding: 30px 20px 20px;
        margin-top: 20px;
        align-items: center;
        background-color: whitesmoke;
        position: relative;

        .sysname {
            position: absolute;
            left: 20px;
            top: 10px;
        }
    }
</style>