export const searchTypeEnum = [
    { name: "姓名",     value: 1 },
    { name: "员工编号", value: 2 },
    { name: "账号",     value: 3 },
    { name: "手机号",   value: 4 }
]

export const stateEnum = [
    {
        name: "正常",
        value: 1
    },
    {
        name: "禁用",
        value: 2
    },
    {
        name: "离职",
        value: 3
    }
]

export const isOrNotEnum = [
    {
        name: "是",
        value: 1
    },
    {
        name: "否",
        value: 0
    }
]