import forEdit from "@/mixin/forEdit";
import {createSystemData, genderEnum} from "./config";
import DepartmentConfig from "./DepartmentConfig.vue";

export default {
    name: 'StaffEdit',
    mixins: [forEdit],
    components: {DepartmentConfig},

    data () {
        const _this = this;
        async function checkSamePhone (rules, val, cb) {
            // console.log(_this);
            const fine = await _this.handlePhoneChange(val);
            if (fine) cb();
            else cb("手机号码重复")
        }
        async function checkSameAccount (rules, val, cb) {
            // console.log(_this);
            const fine = await _this.handleAccontChange(val);
            if (fine) cb();
            else cb("账号重复")
        }
        function pwdValidator (r, value, cb) {
            if (!_this.PB.id) {
                if (!(/^[0-9a-zA-z_]{6,20}$/.test(value)))
                    cb("密码只能是字母或数字，长度6-20位")
                else cb()
            }
            else {
                if (value.length > 0 && !(/^[0-9a-zA-z_]{6,20}$/.test(value)))
                    cb("密码只能是字母或数字，长度6-20位")
                else cb()
            }
        }

        return {
            genderEnum, // 性别选项
            empMap: {}, // 员工信息
            PB: {
                id        : undefined,
                name      : "",
                phone     : "",
                account   : "",
                pwd       : "",
                enName    : "",
                sex       : "",
                age       : "",
                inDate    : "",
                email     : "",
                weixinCode: "",
                remark1   : "",
                empSysList : [],  // 用户已经小保存的系统列表数据，大保存时需要发送的
            },
            rules: {

                name   : { required: true, message: "请输入姓名" },
                phone  : [
                    { required: true, message: "请输入电话" },
                    { validator (r, value, cb) {
                        if (!(/^\+?\d{5,15}$/.test(value)))
                            cb("请输入正确的手机号码格式");
                        else cb();
                    }},
                    { validator: checkSamePhone, trigger: "blur" }
                ],
                account: [
                    { required: true, message: "请输入账号" },
                    { validator (r, value, cb) {
                        if (!(/^[0-9a-zA-z_]{2,30}$/.test(value))) 
                            cb("账号只能是数字或字母, 最少2位")
                        else cb()
                    }},
                    { validator: checkSameAccount, trigger: "blur" }
                ],
                pwd: { validator: pwdValidator },
                email: [
                    { required: true, message: "请输入公司邮箱" },
                    { validator (r, value, cb) {
                        if (!(/^[A-Za-z0-9\u4e00-\u9fa5._]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)))
                            cb("请输入正确的公司邮箱格式")
                        else cb()
                    }},
                ],
                inDate: { required: true, message: "请选择入职日期" },
                empSysList: {required: true, message: "至少需要添加一个系统"}
            },
            systemList: [],     // 用户界面的系统列表数据，包含已保存和未保存的
            activeSystem: null, // 当前激活的系统tab
        }
    },

    created () {
		// this.auth.save = this.$hasAuthFor("api/category/saveCategory") || true;
        if (!this.$route.params.id) this.handleAddSystem();
    },

    methods: {

        // 获取页面数据
        async fetchData() {
			const loading = this.$loading();

			try {
				const res = await this.$axios({
					url: "/api/employee/queryEmpInfo",
					method: "post",
					data: {empId: this.PB.id}
				});

				if (res.code === 2000) {
                    const {empMap} = res.data;
                    this.empMap = empMap;

                    // 把远程的数据回显出来
                    this.PB.name       = empMap.name
                    this.PB.phone      = empMap.phone
                    this.PB.account    = empMap.account
                    this.PB.enName     = empMap.enName  || ""
                    this.PB.sex        = parseInt(empMap.sex) || ""
                    this.PB.age        = empMap.age     || ""
                    this.PB.inDate     = empMap.inDate  || ""
                    this.PB.email      = empMap.email   || ""
                    this.PB.weixinCode = empMap.weixinCode || ""
                    this.PB.remark1    = empMap.remark1 || ""
                    this.PB.empSysList = empMap.empSysList.map(item => ({
                        id               : item.id,
                        system           : item.system,
                        postId           : item.post_id   || "",
                        locate           : item.locate    || "",
                        locateId         : (item.locate && item.locate_id) ? item.locate_id : "",
                        postLevel        : 0,
                        locateLevel      : 0,
                        departmentMixName: item.locatePreName  || "",
                        // manage           : item.manage ? item.manage.split(",") : [],
                        manage           : item.manageList || [],
                        systemName       : item.sysName,
                        saveState        : 1,
                        active           : false,
                    }));
                    this.systemList.push(...this.PB.empSysList)

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
        },
        // 手机号验重
        async handlePhoneChange (val) {
            try {
                const res = await this.$axios({
                    url: "/api/employee/querySamePhone",
                    method: "post",
                    data: {
                        phone: val,
                        id   : this.PB.id
                    }
                })

                if (res.code === 2000) {
                    if (res.data.count) {
                        this.$message.closeAll()
                        this.$message({
                            message: "手机号码重复",
                            type: "warning"
                        });
                        return false
                    } else return true;

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                console.warn(reason);
                return false
            }
        },
        // 账号验重
        async handleAccontChange (val) {
            try {
                const res = await this.$axios({
                    url: "/api/employee/querySameAccount",
                    method: "post",
                    data: {
                        account : val,
                        id      : this.PB.id
                    }
                })

                if (res.code === 2000) {
                    if (res.data.count) {
                        this.$message.closeAll()
                        this.$message({
                            message: "账号重复",
                            type: "warning"
                        });
                        return false
                    } else return true;

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                console.warn(reason);
                return false
            }
        },

        // 添加一个系统
        handleAddSystem () {
            const item = createSystemData();
            this.systemList.push(item);
            this.handleTabClick(item);
        },

        // 移除一个系统
        async handleSystemRemove (item) {
            if (item.id) return this.jumpForRemove(item);

            try {
                if (item.saveState !== 0) {
                    await this.$confirm("确实删除该系统吗?", "提示")
                }

                const {systemList} = this;
                let index = systemList.indexOf(item);
                this.systemList.splice(index, 1);
    
                index = this.findIndexInRegular(item);
                if (index >= 0) this.PB.empSysList.splice(index, 1);

                if (item == this.activeSystem) this.activeSystem = null;
                this.$message({
                    message: "移除成功",
                    type: "success"
                })
                
            } catch (reason) {
                if (reason != "cancel") {
                    console.warn("移除系统的事件处理出错", reason)
                }
            }
        },
        async jumpForRemove (item) {
            try {
                await this.$confirm(
                    `您是要取消${item.systemName}系统的使用权限么?请去业务系统进行离职操作即可`,
                    '取消系统使用权限', {
                        confirmButtonText: '去离职',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                )

                if (item.system === "CEN") return this.handleNavigationBack();
                const sys = this.systemEnum.find(s => s.sysCode === item.system);
                if (!sys.outUrl) return console.log("改配置没有找到匹配的跳转地址", item);

                const loading = this.$loading();
                const res = await this.openSystemURL({
                    url: sys.outUrl,
                    // url: "http://localhost:8030/#/core/staff",
                    system: item.system,
                    params: {
                        empCode: this.empMap.usercode
                    }
                })
                loading.close();
                // if (res !== 2000) throw res;

            } catch (reason) {
                if (reason != "cancel") {
                    console.warn("移除系统的事件处理出错", reason)
                }
            }
        },

        // 查找某个 system 数据在正式数据中的索引 （临时数据和正式数据没
        // 有引用同一个对象，适用JSON.PARSE,JASON.STRINGFY 进行了隔离）
        findIndexInRegular (item) {
            const {empSysList} = this.PB;
            return empSysList.findIndex(it => {
                if (it.id) return it.id === item.id;
                else if (it.nanoid) return it.nanoid === item.nanoid
                else return false
            });
        },

        // 选择系统标签（tab）
        handleTabClick (item) {
            if (item === this.activeSystem) return;
            if (this.activeSystem)
                this.activeSystem.active = false;
            item.active = true;
            this.activeSystem = item;
        },

        // 保存系统（小保存）
        handleSystemSave (manual = false) {
            const {activeSystem: item} = this;

            if (item.saveState === 0) {
                item.saveState = 1;
                this.PB.empSysList.push(JSON.parse(JSON.stringify(item)));

            } else {
                item.saveState = 1;
                const index = this.findIndexInRegular(item);
                this.PB.empSysList.splice(index, 1, JSON.parse(JSON.stringify(item)))
            }

            if (manual) this.$message({
                message: "保存成功",
                type: "success"
            })
        },

        async handleSubmitButton () {
            //
            // await this.$refs.form.validate();
            // await this.validateSystems();



            // 
            // rules: {
            //     name: { required: true, message: "请输入姓名" },
            //     phone: [
            //         { required: true, message: "请输入电话" },
            //         {
            //             validator(r, value, cb) {
            //                 if (!(/^\+?\d{5,15}$/.test(value)))
            //                     cb("请输入正确的手机号码格式");
            //                 else cb();
            //             }
            //         },
            //         { validator: checkSamePhone, trigger: "blur" }
            //     ],
            //         account: [
            //             { required: true, message: "请输入账号" },
            //             {
            //                 validator(r, value, cb) {
            //                     if (!(/^[0-9a-zA-z_]{2,30}$/.test(value)))
            //                         cb("账号只能是数字或字母, 最少2位")
            //                     else cb()
            //                 }
            //             },
            //             { validator: checkSameAccount, trigger: "blur" }
            //         ],
            //             pwd: { validator: pwdValidator },
            //     email: [
            //         { required: true, message: "请输入公司邮箱" },
            //         {
            //             validator(r, value, cb) {
            //                 if (!(/^[A-Za-z0-9\u4e00-\u9fa5._]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)))
            //                     cb("请输入正确的公司邮箱格式")
            //                 else cb()
            //             }
            //         },
            //     ],
            //         inDate: { required: true, message: "请选择入职日期" },
            //     empSysList: { required: true, message: "至少需要添加一个系统" }
            // },
            // 

            if (this.PB.name == '') {
                this.$message.closeAll()
                this.$message({
                    message: "请输入姓名",
                    type: "warning"
                });
                return
            }
            if (this.PB.phone == '') {
                this.$message.closeAll()
                this.$message({
                    message: "请输入电话",
                    type: "warning"
                });
                return
            } else {
                if (!(/^\+?\d{5,15}$/.test(this.PB.phone))) {
                    this.$message.closeAll()
                    this.$message({
                        message: "请输入正确的手机号码格式",
                        type: "warning"
                    });
                    return
                }
            }
            if (this.PB.account == '') {
                this.$message.closeAll()
                this.$message({
                    message: "请输入账号",
                    type: "warning"
                });
                return
            } else {
                if (!(/^[0-9a-zA-z_]{2,30}$/.test(this.PB.account))) {
                    this.$message.closeAll()
                    this.$message({
                        message: "账号只能是数字或字母, 最少2位",
                        type: "warning"
                    });
                    return
                }
            }
            if (this.PB.email == '') {
                this.$message.closeAll()
                this.$message({
                    message: "请输入公司邮箱",
                    type: "warning"
                });
                return
            } else {
                if (!(/^[A-Za-z0-9\u4e00-\u9fa5._]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.PB.email))) {
                    this.$message.closeAll()
                    this.$message({
                        message: "请输入正确的公司邮箱格式",
                        type: "warning"
                    });
                    return
                }
            }
            if (!this.PB.inDate) {
                this.$message.closeAll()
                this.$message({
                    message: "请选择入职日期",
                    type: "warning"
                });
                return
            }
            if (this.PB.empSysList.length == 0) {
                this.$message.closeAll()
                this.$message({
                    message: "至少需要添加一个系统",
                    type: "warning"
                });
                return
            }

            if (!this.PB.id) {
                if (!(/^[0-9a-zA-z_]{6,20}$/.test(this.PB.pwd))) {
                    this.$message({
                        message: "密码只能是字母或数字，长度6-20位",
                        type: "warning"
                    });
                    return
                }
            }
            else {
                if (this.PB.pwd.length > 0 && !(/^[0-9a-zA-z_]{6,20}$/.test(this.PB.pwd))) {
                    this.$message({
                        message: "密码只能是字母或数字，长度6-20位",
                        type: "warning"
                    });
                    return
                }
            }
            const loadingRef = this.$loading();
            try {


                await this.$refs.form.validate();
                await this.validateSystems();
                const {name,phone,account,pwd,enName,sex,age,inDate,email,weixinCode,remark1,empSysList} = this.PB;
                const data = {
                    name,phone,account,enName,sex,age,inDate,email,weixinCode,remark1,
                    empSysList  : JSON.stringify(empSysList.map(it => ({
                        system  : it.system,
                        postId  : it.postId,
                        locate  : it.locate,
                        locateId: it.locateId,
                        manage  : it.manage.map(m => m.code || m).join(),
                        manageId: it.manage.map(m => m.id || "").join()
                    }))),
                    id: this.PB.id
                }
                if (pwd) {
                    console.log("before encrypto", pwd);

                    const CryptoJS = window.CryptoJS;
                    // var key       = CryptoJS.enc.Utf8.parse("CENTER001");
                    var key       = CryptoJS.enc.Utf8.parse("YIMING5AUS900001");
                    var srcs      = CryptoJS.enc.Utf8.parse(pwd);
                    var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
                    data.pwd      = encrypted.toString();

                    // data.pwd = this.$CryptoJS.AES.encrypt(pwd, "center001").toString()
                }

                // console.log("提交的数据", data);
                const res = await this.$axios({
                    url: "/api/employee/saveEmp",
                    method: "post",
                    data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.handleNavigationBack();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                // console.log(reason)
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"

                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        async validateSystems () {
            if (this.systemList.some(sys => {
                if (!sys.locateId) {
                    this.activeSystem = sys;
                    const fine = this.$refs.departmentconfig.handleSaveButton();
                    if (!fine) return Promise.reject(false);
                    return true;
                }
                return false;
            })) {
                return Promise.reject(false);
            }
            else return;
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        },
        handleSuggestInputFocus (e) {
            setTimeout(() => {
                e.target.removeAttribute("readonly")
            }, 1);
        },
        handleSuggestInputBlur (e) {
            e.target.setAttribute("readonly", "readonly")
        },
    }
}