<!-- 关联账号 -->
<template>
    <el-dialog class="link-account"
        :visible="viewIsReady"
        @close="handleClose"
        title="关联账号"
        width="800px">
        
        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >

            <div class="tips">
                <img src="./images/warm_icon.png">
                <span>温馨提示：账号关联后，一次登录可以任意切换关联账号，请谨慎使用</span>
            </div>

            <el-form-item label="当前账号" class="no_bottom_gap">{{ placeItem.account }}</el-form-item>
            <el-form-item label="已关联的账号有" v-if="teamAccountList.length">
                <ul class="linking_list">
                    <li v-for="item in teamAccountList" :key="item.empid">
                        <span>{{item.usercode}}-{{item.account}}-{{item.name}}</span>
                        <el-button type="text"
                            v-if="auth.delete"
                            @click="handleRemoveBtn(item)"
                        >解绑</el-button>
                    </li>
                </ul>
            </el-form-item>

            <el-form-item label="新增关联账号" prop="teamEmpId">
                <el-autocomplete
                    placeholder="请输入关联账号"
                    v-model="selectedAccountText"
                    :fetch-suggestions="querySearch"
                    @select="handleSelect($event, 'teamEmpId')"
                    class="width3"></el-autocomplete>
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">关闭</el-button>
            <el-button @click="handleSave" type="primary"
                :disabled="!PB.teamEmpId">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'LinkAccount',
    props: ["item"],
    inject: ["fetchTeamAccountList"],

    data () {
        return {
            viewIsReady: false,
            teamAccountList: [],
            selectedAccount: null,
            selectedAccountText: "",
            PB: {
                empid    : "",
                teamEmpId: ""
            },
            rules: {
                teamEmpId: {required: true, message: "请输入关联账号"}
            },
            auth: {
                delete: false
            },
        }
    },

    mounted () {
        this.auth.delete = this.$hasAuthFor("api/employee/deleteTeamAccount");
    },

    computed: {
        placeItem () {
            return this.item || {}
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.PB.empid = item.id;
                this.viewIsReady = true;
                this.fetchList(item.id);
            }
        }
    },

    methods: {
        async fetchList (empid) {
            const loading = this.$loading({
                target: ".link-account .el-dialog"
            });

            try {
				const res = await this.$axios({
					url: "/api/employee/getTeamAccount",
					method: "post",
					data: {empid}
				});

				if (res.code === 2000) {
					this.teamAccountList = res.data.teamAccountList;

					loading.close();
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
        },

        async handleRemoveBtn (accout) {
            const index = this.teamAccountList.indexOf(accout);
            let loading;
            
            try {
                await this.$confirm("确实解绑吗?", "提示");

                loading = this.$loading({
                    target: ".link-account .el-dialog"
                });
                const res = await this.$axios({
					url: "api/employee/deleteTeamAccount",
					method: "post",
					data: {
                        empid: this.PB.empid,
                        teamEmpId: accout.empid
                    }
				});

				if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    this.teamAccountList.splice(index,1);
                    if (this.PB.empid == this.userInfo.employeeId || accout.empid == this.userInfo.employeeId)
                        this.fetchTeamAccountList();

				} else if (res.code !== 1003) throw res

            } catch (reason) {
                if (reason != "cancel") {
                    this.$message({
                        message: reason.msg || "操作失败",
                        type: "warning"
                    });
                }
            }

            loading?.close();
        },

        async querySearch(queryString, cb) {
            try {
				const res = await this.$axios({
					url: "/api/employee/queryLikeAccount",
					method: "post",
					data: {
                        value: queryString,
                    }
				});

				if (res.code === 2000) {
                    cb(res.data.teamAccountList.map(item => {
                        item.value = [item.account, item.name].filter(Boolean).join(" - ")
                        return item;
                    }));

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
			}
        },

        handleSelect(item, key) {
            this.selectedAccount = item;
            this.PB[key] = item.empid;
        },

        async handleSave () {
            const loadingRef = this.$loading({
                target: ".apply-channel .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/employee/saveTeamAccount",
                    method: "post",
                    data: { ...this.PB }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");

                    if (this.PB.empid == this.userInfo.employeeId || this.selectedAccount.empid == this.userInfo.employeeId)
                        this.fetchTeamAccountList();
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.teamAccountList = [];
            this.selectedAccount = null;
            this.selectedAccountText = "";
            this.PB.empid        = "";
            this.PB.teamEmpId    = "";
            this.$nextTick().then(() => {
                this.$refs.form.clearValidate();
            })
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .tips {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;
        color: #D40000;

        img {
            margin-right: 8px;
        }
    }

    .el-form.private .no_bottom_gap { // 前面的增加权重
        margin-bottom: 0;
    }

    .linking_list {
        width: 100%;

        li {
            display: flex;
            // line-height: 1;
            // margin-top: 9px;
    
            &:hover {
                background-color: rgba(42, 121, 255, 0.1);
            }
            
            // &:nth-child(n+2) {
            //     margin-top: 16px;
            // }
    
            span {
                flex: 0 0 300px;
            }
    
            .el-button {
                padding: 0;
                border: 0;
                // height: 1em;
            }
        }
    }
</style>