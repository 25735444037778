import {nanoid} from "nanoid";

export function createSystemData () {
    return {
        system: "",
        postId: "",
        locate: "",
        manage: [],
        
        systemName : "",
        postLevel  : 0,
        locateLevel: 0,
        departmentMixName: "",
        saveState: 0, // 0为保存，1已保存，2自保存后有新数据为保存
        active: false,
        nanoid: nanoid(),
    }
}

export const genderEnum = [
    { name: "男", value: 1},
    { name: "女", value: 2}
]